import React, { FC } from 'react';
import useHeader from '../../../hooks/useHeader';
import { Main, Container } from '../../../components/Layout';
import { TableWrapper } from '../../../components/Common';
import '../PromotorFinish/PromotorFinish.scss';

const PromotorFinish: FC = () => {
  useHeader('Promotor - zakończenie', '/');

  return (
    <Main className="promotor-finish">
        <img className='main-image' src='/assets/images/promotor_ii_zwycięzcy_dlugi_baner.jpeg' />
      <Container>
        <p className="bigger">Brawo Top Dekarze!</p>
        <p>
          II edycja Promotora przerosła nasze najśmielsze oczekiwania. Doceniamy Wasze zaangażowanie i tak aktywne
          promowanie swoich firm z wykorzystaniem banerów CREATON
        </p>
        <p className="big">Znamy już zwycięzców, gratulujemy!</p>
        <TableWrapper>
          <table className="table">
            <thead>
              <th>Imię</th>
              <th>Nazwisko</th>
            </thead>
            <tbody>
              <tr>
                <td>Andrzej</td>
                <td>G.</td>
              </tr>
              <tr>
                <td>Dawid</td>
                <td>P.</td>
              </tr>
              <tr>
                <td>Bartosz</td>
                <td>R.</td>
              </tr>
            </tbody>
          </table>
        </TableWrapper>
        <p className='big'><strong>Panowie, Waszą nagrodą są elektronarzędzia o wartości 3500 zł! Zastanówcie się dobrze bo już wkrótce, skontaktujemy się z Wami, byście mogli dokonać wyboru.</strong></p>
        <p>Nie zostało dużo czasu do końca II edycji Top Dekarzy! Walczcie do końca i nie poddawajcie się! Wszystko jest możliwe bo wszystko zależy tylko od Was!</p>
      </Container>
    </Main>
  );
};

export default PromotorFinish;
