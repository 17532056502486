import React, { FC } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import { format } from 'date-fns';
import { ArticleRes } from 'topdekarze-types';
import { sign } from 'crypto';
import { Link } from 'react-router-dom';
import { Main, Container, Column } from '../../Layout';
import Icon from '../Icon';
import ApiService from '../../../services/api-service';
import TransactionVerifyMenu from '../TransactionVerifyMenu';
import PageTitle from '../PageTitle';

import './HomeNewsMobile.scss';
import Image from '../Image';

interface Props {
  content: any;
}

const HomeNewsMobile: FC<Props> = ({ content }) => {
  if (content && content.length > 0) {
    content.push({ bg: 'baner_novaPaka_v3.jpg', path: '/dashboard/main-prize' });
  } else return null;

  return (
    <section className="home-news-mobile">
      <Container className="carousel" fluid>
        <Column xs={12}>
          <PageTitle>Aktualności</PageTitle>
        </Column>
        <Carousel
          arrows
          arrowLeft={<Icon className="carousel-icon" icon="left-arrow" />}
          arrowRight={<Icon className="carousel-icon" icon="right-arrow" />}
          addArrowClickHandler
          draggable
          slidesPerPage={1}
        >
          {content &&
            content.length > 0 &&
            content.map((singleBox: any) => {
              if (!singleBox.bg) {
                return (
                  <div className="carousel-item-wrapper">
                    <Link to={`/dashboard/articles/view/${singleBox.id}`}>
                      <img src={`${ApiService.url}article/image/${singleBox.id}`} alt="" />
                      <div className="title">{singleBox.title}</div>
                    </Link>
                  </div>
                );
              } else {
                return (
                  <div className="carousel-item-wrapper">
                    {/* <Link to="/dashboard/main-prize">
                      <Image image="baner_novaPaka_v3.jpg" />
                    </Link> */}
                    {/* <Link to="/dashboard/tytani-wyniki">
                      <Image image="tytani_wyniki_baner.jpg" />
                    </Link> */}
                    {/* <Image image="womens_day_winners.jpeg" /> */}
                    {/* <Link to="/dashboard/cementowe-finish">
                      <Image image="cementowe_love.jpg" />
                    </Link> */}
                    {/* <Link to="/dashboard/promotor-2-finish">
                      <Image image="poznaj_zwyciezcow_promotor_ii.jpeg" />
                    </Link> */}
                    <Link to="/dashboard/the-end" className="news-box main">
                      <Image image="supernagroda-the-end.jpeg" />
                    </Link>
                    {/* <Image image="wielkanoc_kartka.jpeg" /> */}
                  </div>
                );
              }
            })}
        </Carousel>
      </Container>
    </section>
  );
};

export default HomeNewsMobile;
